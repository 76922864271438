import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  List,
} from "@mui/material";
import { parseVersion, getPageIdentifier,getGlobalDesignObject,
  handleUpdateGlobalDesignObject, } from "../../Services";
import {
  Add as AddIcon,
  ArrowBack,
  FileCopy as CloneIcon,
  Check as CurrentVersionIcon,
} from "@mui/icons-material";


const VersionsTab = ({
  onClose,
  handleChangeCurrentVersion,
  versions,
  // setVersions,
  setOpenedSidebar,
  currentVersion,
  saveDesignObject,
  saveNewVersion,
}) => {
  const [selectedAction, setSelectedAction] = useState(null);

  const [newKey, setNewKey] = useState("");
  const [cloneKey, setCloneKey] = useState({});
  const [version, setVersion] = useState(null);

  const isKeyDuplicate = (key) => {
    return version?.hasOwnProperty(key);
  };

  useEffect(() => {
    // let savedStyles = JSON.parse(localStorage.getItem('elementStyles'));
    let savedStyles = getGlobalDesignObject();
    savedStyles = savedStyles || {};
    const pageIdentifier = getPageIdentifier();
    savedStyles = savedStyles[pageIdentifier];
    //setVersion(savedStyles);
  }, []);

  useEffect(() => {
    if (version != null) {
      let saveStyles = {};
      saveStyles[getPageIdentifier()] = version;
      // localStorage.setItem('elementStyles', JSON.stringify(saveStyles));
      handleUpdateGlobalDesignObject(saveStyles, 'VersionsTab useEffect');
      saveDesignObject();
    }
  }, [version]);

  const cloneVersion = async (key) => {
    const newKeyName = cloneKey[key];
    if (!newKeyName) {
      alert("Key name cannot be empty");
      return;
    }
    if (isKeyDuplicate(newKeyName)) {
      alert("Key name already exists");
      return;
    }
    // setVersions((prev) => ({ ...prev, [newKeyName]: prev[key] }));
    setCloneKey((prev) => ({ ...prev, [key]: "" })); // Reset input field after cloning
    await saveNewVersion(newKeyName)

  };

  const addNewVersion = () => {
    if (!newKey) {
      alert("Key name cannot be empty");
      return;
    }
    if (isKeyDuplicate(newKey)) {
      alert("Key name already exists");
      return;
    }
    // setVersions((prev) => ({ ...prev, [newKey]: "" }));
    setTimeout(async () => {
      await saveNewVersion(newKey, true)
      goBack();
    }, 0);
  };
  const cloneExistingVersion = () => {
    if (!newKey) {
      alert("Key name cannot be empty");
      return;
    }
    if (isKeyDuplicate(newKey)) {
      alert("Key name already exists");
      return;
    }

    setTimeout(async () => {
      await saveNewVersion(newKey, false)
      goBack();
    }, 0);
  };
  const goBack = () => {
    setSelectedAction(null);
    setNewKey("");
  };

  // console.log('versionsTab',token, versions, currentVersion, cloneKey, newKey)

  return (
    <DialogContent
      open={true}
      onClose={() => setOpenedSidebar("")}
    >
      <DialogTitle style={{ marginBottom: "1.5rem", paddingLeft: "0" }}>
        Version Manager
      </DialogTitle>

      <Grid
        container
        spacing={2}
        style={{ maxHeight: "350px", overflowY: "auto" }}
      >
        <Grid
          item
          xs={12}
        >
          {!selectedAction && (
            <Grid
              container
              spacing={3}
              justifyContent={"center"}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => setSelectedAction("create")}
                >
                  Create Version
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => setSelectedAction("clone")}
                >
                  Clone "{currentVersion}"
                </Button>
              </Grid>
            </Grid>
          )}
          {selectedAction && (
            <Box sx={{ mb: 2 }}>
              <Button
                startIcon={<ArrowBack />}
                onClick={() => goBack()}
              >
                Go Back
              </Button>
            </Box>
          )}
          {selectedAction === "create" && (
            <Grid
              container
              spacing={2.5}
              alignItems={"center"}
            >
              <Grid
                item
                flexGrow={1}
              >
                <TextField
                  label="New version name"
                  value={newKey}
                  onChange={(e) => setNewKey(e.target.value)}
                  size="small"
                  style={{ minWidth: "200px" }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={newKey.length < 3}
                  onClick={addNewVersion}
                  fullWidth
                >
                  Create Version
                </Button>
              </Grid>
            </Grid>
          )}
          {selectedAction === "clone" && (
            <Grid
              container
              spacing={2.5}
              alignItems={"center"}
            >
              <Grid
                item
                flexGrow={1}
              >
                <TextField
                  label="Cloned version name"
                  value={newKey}
                  onChange={(e) => setNewKey(e.target.value)}
                  size="small"
                  style={{ minWidth: "200px" }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={newKey.length < 3}
                  onClick={cloneExistingVersion}
                  fullWidth
                >
                  Clone Version
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/*  */}
        <List>
          {version &&
            Object.keys(version).map((key) => (
              <ListItem
                key={key}
                style={{ display: "flex", gap: "1rem" }}
              >
                <ListItemText primary={key} />
                <TextField
                  size="small"
                  value={cloneKey[key] || ""}
                  onChange={(e) =>
                    setCloneKey((prev) => ({ ...prev, [key]: e.target.value }))
                  }
                  placeholder="Enter new key name"
                />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => cloneVersion(key)}>
                    <CloneIcon />
                  </IconButton>
                  <IconButton onClick={() => handleChangeCurrentVersion(key)}>
                    {currentVersion === key ? (
                      <CurrentVersionIcon color="primary" />
                    ) : (
                      <CurrentVersionIcon />
                    )}
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
        {/*  */}
      </Grid>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </DialogContent>
  );
};

export default VersionsTab;
