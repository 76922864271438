import axios from "axios";
// import { globalDesignObject } from "./App";
const MOBILE_MAX_WIDTH = 767; // Max width for mobile devices
const TABLET_MAX_WIDTH = 1023; // Max width for tablet devices
export const iframeElement = document.querySelector("#viewport-preview");
export const innerPage = iframeElement?.contentDocument || document;

const getIframePage = () => {
  const iframeElement = document.querySelector("#viewport-preview");
  return iframeElement?.contentDocument || document;
}

export function getDeviceMode() {
  if (!innerPage) return null;
  
  // Get the window object associated with the document
  const innerWindow = innerPage.defaultView || window;
  
  // Now we can access innerWidth
  const width = innerWindow.innerWidth;
  
  console.log('saveDesignObjectFunc_getDeviceMode', iframeElement, innerPage, width);
  
  if (width <= MOBILE_MAX_WIDTH) {
    return "mobile";
  } else if (width <= TABLET_MAX_WIDTH) {
    return "tablet";
  } else {
    return "desktop";
  }
}

let globalDesignObject = null;
// let globalDesignObject = null;

export const getGlobalDesignObject = () => {
  if (typeof globalDesignObject === 'string') {
    try {
      return JSON.parse(globalDesignObject);
    } catch (error) {
      console.error('Error parsing globalDesignObject:', error);
      return {};  // Return an empty object if parsing fails
    }
  } else if (typeof globalDesignObject === 'object' && globalDesignObject !== null) {
    return globalDesignObject;
  } else {
    return {};  // Return an empty object if globalDesignObject is neither a string nor an object
  }
};

export const handleUpdateGlobalDesignObject = (newObj, source = null) => {
  console.log("getStyleForModeRevisions handleUpdateGlobalDesignObject", newObj, source)
  globalDesignObject = newObj;
};

export const parseVersion = () => {
  //* let savedStyles = JSON.parse(localStorage.getItem("elementStyles"));
  let savedStyles = getGlobalDesignObject();
  savedStyles = savedStyles || {};
  // console.log('savedStylesVersion', savedStyles)
  const pageIdentifier = getPageIdentifier();
  // console.log('savedStylesVersion pageIdentifier', pageIdentifier)

  savedStyles = savedStyles[pageIdentifier];
  // console.log('savedStylesVersion', savedStyles)
  return { versions: savedStyles };
};

export const setImageSourceStyles = (
  savedStyles,
  resolutionMode,
  element,
  version,
) => {
  // console.log('applyImageSourceStyles', savedStyles)

  const styles = getStyleForMode(
    savedStyles,
    resolutionMode,
    element.id,
    "image",
    version,
  );

  // console.log('applyImageSourceStyles', savedStyles, styles)
  if (styles) {
    if (element.tagName === "IMG" && styles.imageUrl) {
      element.src = styles.imageUrl;
    } else if (styles.imageUrl) {
      element.style.backgroundImage = `url('${styles.imageUrl}')`;
    }
  } else {
    element.removeAttribute("style");
  }
};
export const getStyleForMode = (
  savedStyles,
  resolutionMode,
  elementId,
  styleGroup,
  version,
) => {
  const defaultResolutionMode = "desktop";
  const pageIdentifier = getPageIdentifier();
  // console.log("getStyleForMode", savedStyles,
  //   resolutionMode,
  //   elementId,
  //   styleGroup,
  //   version,);
  /* ////console.log("",);*/

  if (!savedStyles) {
    // if (!savedStyles[pageIdentifier] || !savedStyles[pageIdentifier][version]) {
    return null;
  }
  var versionStyles = savedStyles;
  // var versionStyles = savedStyles[pageIdentifier][version];
  if (styleGroup == "globalStylesObj") {
    //  console.log("debugVersion", versionStyles);
    //  console.log("debugVersion version", version);
  }
  let resultStyles = versionStyles;
  if (
    resultStyles &&
    resultStyles[styleGroup] &&
    resultStyles[styleGroup][elementId]
  ) {
    return resultStyles[styleGroup][elementId];
  }
  resultStyles = versionStyles[defaultResolutionMode];
  // Fallback to the default resolutionMode if current resolutionMode styles don't exist
  if (
    resolutionMode !== defaultResolutionMode &&
    resultStyles &&
    resultStyles[styleGroup] &&
    resultStyles[styleGroup][elementId]
  ) {
    return resultStyles[styleGroup][elementId];
  }

  // If in 'tablet' resolutionMode and styles are missing, try 'mobile' next
  if (
    resolutionMode === "tablet" &&
    versionStyles["mobile"] &&
    versionStyles["mobile"][styleGroup] &&
    versionStyles["mobile"][styleGroup][elementId]
  ) {
    return versionStyles["mobile"][styleGroup][elementId];
  }

  // Return null if no styles are found
  return null;
};
export const getStyleForModeRevisions = (
  savedStyles,
  elementId,
  styleGroup
) => {

  let resultStyles = savedStyles;
  if (
    resultStyles &&
    resultStyles[styleGroup] &&
    resultStyles[styleGroup][elementId]
  ) {
    console.log("getStyleForModeRevisions return", savedStyles)
    return resultStyles[styleGroup][elementId];
  }


  // Return null if no styles are found
  return null;
};
export const getCurrentStylesObject = (version, resolutionMode) => {
  // const savedStyles = JSON.parse(localStorage.getItem("elementStyles")) || {};
  const savedStyles = getGlobalDesignObject() || {};
  console.log("getStyleForModeRevisions getCurrentStylesObject: ", savedStyles);

  return savedStyles;
};

export const getPageIdentifier = () => {
  const iframeElement = document.querySelector("#viewport-preview");
  const innerPage = iframeElement?.contentDocument || document;
  const bodyEl = innerPage.querySelector("[data-globalPageIdentifier]");
  // console.log("bodyEl", bodyEl, innerPage);
  const pageIdentifier = bodyEl?.getAttribute("data-globalPageIdentifier");
  // console.log("pageIdentifier", pageIdentifier);
  return pageIdentifier;
};
export const setElPositionStyles = (style, currentElementId) => {
  // Assuming `currentElement` is a reference to the element you want to update
  const innerPage = getIframePage();
  const currentElement = innerPage.getElementById(currentElementId);
  ////console.log("debug saved elPosition finally styles to set", style);
  ////console.log("debug saved elPosition finally styles to set", currentElementId);
  if (currentElement) {
    ////console.log("debug saved elPosition finally style.top", style.top);
    currentElement.style.top = style.top !== null ? `${style.top}px` : "auto";
    currentElement.style.left =
      style.left !== null ? `${style.left}px` : "auto";
    currentElement.style.marginTop =
      style.marginTop !== null ? `${style.marginTop}px` : "auto";
    currentElement.style.marginLeft =
      style.marginLeft !== null ? `${style.marginLeft}px` : "auto";
    currentElement.style.marginBottom =
      style.marginBottom !== null ? `${style.marginBottom}px` : "auto";
    currentElement.style.marginRight =
      style.marginRight !== null ? `${style.marginRight}px` : "auto";
  }
};
export const setImgElBckPstn = (imgPstn, el) => {
  console.log("we try to set background position", imgPstn);
  console.log("we try to set background position imgPstn.xPosition", imgPstn.xPosition);
  if (el) {
    el.style.backgroundPosition = `${imgPstn.xPosition}% ${imgPstn.yPosition}%`;
    el.style.backgroundSize = imgPstn.backgroundSize;
    el.style.backgroundRepeat = imgPstn.backgroundRepeat;
    el.style.backgroundAttachment = imgPstn.backgroundAttachment;
  }
};
export function generateCSS(styleGroups) {
  return styleGroups
    .map((group) => {
      const groupStyles = Object.entries(group.styles)
        .map(([selector, rules]) => {
          const properties = rules
            .map((rule) => `${rule.rule}: ${rule.value};`)
            .join(" ");
          return `${selector} { ${properties} }`;
        })
        .join(" ");

      // Include the group title as a comment
      return `/* ${group.title} */\n${groupStyles}`;
    })
    .join("\n\n");
}

export function getOriginalStyles() {
  const savedStyles = JSON.parse(localStorage.getItem("originalStyles")) || {};

  console.log('getOriginalStyles', savedStyles)
  savedStyles[getPageIdentifier()] = savedStyles[getPageIdentifier()] || {};
  return savedStyles[getPageIdentifier()]["originalStyles"];
}

export function resetGlobalStyles() {
  const originalStyles = getOriginalStyles();
  const innerPage = getIframePage();
  // const styleTag = innerPage.getElementById("dynamic-styles");
  console.log('resetGlobalStylesinnerPage', innerPage, originalStyles);
  const styleTag = innerPage.getElementById("dynamic-styles");
  if (styleTag && originalStyles) {
    styleTag.innerHTML = originalStyles;
  }
}
export function setOriginalStyles() {
  const innerPage = getIframePage();
  const styleTag = innerPage.getElementById("dynamic-styles");
  const savedStyles = JSON.parse(localStorage.getItem("originalStyles")) || {};
  //console.log("debugVersion pageIdentifier", pageIdentifier);
  // console.log('setOriginalStyles', styleTag.innerHTML)
  savedStyles[getPageIdentifier()] = savedStyles[getPageIdentifier()] || {};
  console.log(
    "debugResetStyles saved styles before ",
    savedStyles,
    "styleTag: ",
    styleTag,
    "savedStyles[getPageIdentifier()]: ", savedStyles[getPageIdentifier()],
  );
  savedStyles[getPageIdentifier()]["originalStyles"] = styleTag.innerHTML;
  localStorage.setItem("originalStyles", JSON.stringify(savedStyles));
  console.log("Original Styles Set");
}

export function updateDynamicStyles(styleGroups, from = "updateDynamicStyles") {
  console.log('setOriginalStyles ON UPDATE DYNAMIC STYLES',styleGroups, "from", from)
  const styles = generateCSS(styleGroups);
  const styleTag = innerPage.getElementById("dynamic-styles");
  const originalStyles = getOriginalStyles();
  console.log("debugResetStyles originalStyles", originalStyles);
  if (!originalStyles) {
    setOriginalStyles();
  }
  if (styleTag) {
    styleTag.innerHTML = styles;
  }
}

export function applyGlobalStyles(savedStyles, currentResolutionMode, version) {
  
  const globalStylesObj = getStyleForMode(
    savedStyles,
    currentResolutionMode,
    "global",
    "globalStylesObj",
    version,
  );
  console.log(
    "globalStylesObj_applyGlobalStyles 1:",
    savedStyles,
    currentResolutionMode,
    version,
  );
  // Reset global styles in case some of them are missing
  resetGlobalStyles();
  // console.log("globalStyles_obj", globalStylesObj);
  if (globalStylesObj != undefined && globalStylesObj != null) {
    // console.log("globalStylesObj_applyGlobalStyles 3:", savedStyles);
    updateDynamicStyles(globalStylesObj);
  }
}

export async function callApi(data, action) {
  try {
    // Define the endpoint URL
    data = { ...data, ...{ action } };
    //console.log("debugSaveAPI our data", data);
    const url = `https://design.codersuccess.com/wp-admin/admin-ajax.php`;
    // Create a FormData instance
    const formData = new FormData();

    // Iterate over the data object and append each item to the FormData
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    // Make the POST request and await its response
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    //console.log("debugSaveAPI our response", response.data);

    // You can return the response data if needed
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    // You can throw the error or return it depending on how you want to handle errors
    throw error;
  }
}

export function getQueryParams(url) {
  const params = {};
  const urlParams = new URLSearchParams(url);
  for (const [key, value] of urlParams.entries()) {
    params[key] = value;
  }
  return params;
}

export function insertDuplicatedSection(section) {
  const iframeElement = document.querySelector("#viewport-preview");
  //* const parentSectionNode = iframeElement?.contentDocument?.querySelector(
  //*   `[data-section-toggle="${section.duplicateId}"]`,
  //* );
  const parentSectionNode = iframeElement?.contentDocument?.getElementById(
    section.duplicateId,
  );
  if (parentSectionNode) {
    const duplicateSectionNode = parentSectionNode.cloneNode(true);
    duplicateSectionNode.id = parentSectionNode.id + "-copy";
    duplicateSectionNode.setAttribute("data-section-toggle", section.name);
    duplicateSectionNode.setAttribute("data-section", section.name);
    duplicateSectionNode.setAttribute("data-style", section.id);

    const allIdElements = duplicateSectionNode.querySelectorAll("[id]");
    allIdElements.forEach((elem) => {
      elem.id = elem.id + "-copy";

      // ["data-text", "data-remove"].forEach((attr) => {
      //   if (elem.hasAttribute(attr))
      //     elem.setAttribute(attr, elem.getAttribute(attr) + " copy");
      // });
    });

    parentSectionNode.after(duplicateSectionNode);
  }
}

export function getElementPositionAndSize(element) {
  const rect = element.getBoundingClientRect();
  const x = rect.left + window.scrollX;
  const y = rect.top + window.scrollY;
  const width = rect.width;
  const height = rect.height;

  return { x, y, width, height };
}

//export { getStyleForMode };
//export default Services;
