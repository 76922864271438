import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Grid,
  Divider,
  useTheme,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";

const SidebarNav = ({
  addImageIcons,
  addElPositionIcons,
  addPositionIcons,
  addStyleIcons,
  openGlobalStyles,
  togglePencils,
  toggleVersionPopup,
  removeAllIcons,
  setOpenedSidebar,
  isSidebarOpen,
  setIsSidebarOpen,
  token,
  allPages,
  handlePageChange,
  versions,
  version,
  setVersion,
  handleChangeCurrentVersion,
  isLoggedIn,
  selectedPage,
  handleSavePageToPlatform,
  htmlContentLoading,
  addRemoveItemsSectionsIcons,
  fetchDesignObject
}) => {
  const [activeOption, setActiveOption] = useState("versionPopup");


  const handleOptionClick = (option) => {
    console.log("handleOptionClick", option, activeOption);
    if (!isLoggedIn) return; // do not toggle login tab if not logged in
    // when clicked the same tab again reset selected tab and remove edit buttons
    if (activeOption === option) {
      removeAllIcons();
      setOpenedSidebar("");
      setActiveOption("");
      return;
    }
    setActiveOption(option);
    setOpenedSidebar("");
    removeAllIcons();

    switch (option) {
      case "editText":
        console.log("handleOptionClick switch", option, activeOption);
        togglePencils();
        break;
      case "editMenu":
        setOpenedSidebar("editMenu");
        break;
      case "imageUpload":
        addImageIcons();
        break;
      case "popupImgBckgrndPstn":
        addPositionIcons();
        break;
      case "popupElementBckgrndPstn":
        addElPositionIcons();
        break;
      case "stylePopup":
        addStyleIcons();
        break;
      case "globalStylePopup":
        openGlobalStyles();
        break;
      case "versionPopup":
        toggleVersionPopup();
        setOpenedSidebar(option);
        break;
      case "Escape":
        removeAllIcons();
        setOpenedSidebar("");
        break;
      case "sectionsTab":
        removeAllIcons();
        setOpenedSidebar(option);
        break;
      case "removeElements":
        // setOpenedSidebar(option);
        addRemoveItemsSectionsIcons();
        break;
      case "publish":
        setOpenedSidebar("publish");
        break;
      default:
        break;
    }
  };

  console.log("SidebarNav versions", versions);

  if (isSidebarOpen) {
    return (
      <Grid
        item
        xs={12}
        sm="auto"
        className="sidebar-menu"
      >
        <CloseIcon
          className="sidebar-menu__close-btn"
          onClick={() => setIsSidebarOpen(false)}
          style={{ left: "100%" }}
        />
        {isLoggedIn ? (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              paddingBottom: "16px",
              position: "relative",
              "&:after": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "1px",
                boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.3)",
              },
            }}
          >
            <>
              <Button
                variant={activeOption === "editText" ? "contained" : "outlined"}
                color={activeOption === "editText" ? "primary" : "secondary"}
                onClick={() => handleOptionClick("editText")}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Edit Texts
              </Button>
              <Button
                variant={activeOption === "editMenu" ? "contained" : "outlined"}
                color={activeOption === "editMenu" ? "primary" : "secondary"}
                onClick={() => handleOptionClick("editMenu")}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Edit Menu
              </Button>
              <Button
                variant={
                  activeOption === "imageUpload" ? "contained" : "outlined"
                }
                color={activeOption === "imageUpload" ? "primary" : "secondary"}
                onClick={() => handleOptionClick("imageUpload")}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Image Upload
              </Button>
              <Button
                variant={
                  activeOption === "popupImgBckgrndPstn"
                    ? "contained"
                    : "outlined"
                }
                color={
                  activeOption === "popupImgBckgrndPstn"
                    ? "primary"
                    : "secondary"
                }
                onClick={() => handleOptionClick("popupImgBckgrndPstn")}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Background Image Position
              </Button>
              <Button
                variant={
                  activeOption === "popupElementBckgrndPstn"
                    ? "contained"
                    : "outlined"
                }
                color={
                  activeOption === "popupElementBckgrndPstn"
                    ? "primary"
                    : "secondary"
                }
                onClick={() => handleOptionClick("popupElementBckgrndPstn")}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Element Position
              </Button>
              <Button
                variant={
                  activeOption === "globalStylePopup" ? "contained" : "outlined"
                }
                color={
                  activeOption === "globalStylePopup" ? "primary" : "secondary"
                }
                onClick={() => handleOptionClick("globalStylePopup")}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Global Style Editor
              </Button>
              <Button
                variant={
                  activeOption === "stylePopup" ? "contained" : "outlined"
                }
                color={activeOption === "stylePopup" ? "primary" : "secondary"}
                onClick={() => handleOptionClick("stylePopup")}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Style Editor
              </Button>
              <Button
                variant={
                  activeOption === "sectionsTab" ? "contained" : "outlined"
                }
                color={activeOption === "sectionsTab" ? "primary" : "secondary"}
                onClick={() => handleOptionClick("sectionsTab")}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Sections
              </Button>
              <Button
                variant={
                  activeOption === "removeElements" ? "contained" : "outlined"
                }
                color={
                  activeOption === "removeElements" ? "primary" : "secondary"
                }
                onClick={() => handleOptionClick("removeElements")}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Remove Elements
              </Button>
              <Button
                variant={
                  activeOption === "versionPopup" || isLoggedIn === false
                    ? "contained"
                    : "outlined"
                }
                color={
                  activeOption === "versionPopup" || isLoggedIn === false
                    ? "primary"
                    : "secondary"
                }
                onClick={() => handleOptionClick("versionPopup")}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Login
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  fontSize: 12,
                  height: 40,
                }}
                variant={activeOption === "publish" ? "contained" : "outlined"}
                color={activeOption === "publish" ? "success" : "secondary"}
                onClick={() => handleOptionClick("publish")}
              // onClick={() => handleSavePageToPlatform()}
              // disabled={htmlContentLoading}
              // endIcon={htmlContentLoading && <CircularProgress size={16} />}
              >
                Publish
              </Button>
            </>
          </Box>
        ) : (
          ""
        )}
        {token && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: "8px",
              paddingBottom: "16px",
              paddingTop: "16px",
              position: "relative",
              "&:after": {
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "1px",
                boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.3)",
              },
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                // justifyContent: "center",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ m: 1, width: 200 }}>
                <InputLabel id="demo-simple-select-helper-label">
                  Page
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={selectedPage ? selectedPage : ""}
                  label="Page"
                  onChange={(e) => {
                    handlePageChange(e);
                    removeAllIcons();
                    setOpenedSidebar("");
                    setActiveOption("");
                  }}
                  renderValue={(selected) =>
                    selected ? selected : "Select page"
                  }
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Select page
                  </MenuItem>
                  {allPages.map((page) => (
                    <MenuItem
                      key={page.pageSlug}
                      value={page.pageSlug}
                    >
                      {page.pageSlug}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant={activeOption === "pagesTab" ? "contained" : "outlined"}
                color={activeOption === "pagesTab" ? "primary" : "secondary"}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setOpenedSidebar("pagesTab");
                  setActiveOption("pagesTab");
                }}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Pages Management
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                // justifyContent: "center",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ m: 1, width: 200 }}>
                <InputLabel id="demo-simple-select-helper-label2">
                  Version
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label2"
                  id="demo-simple-select-helper2"
                  value={
                    versions && version ? version : "No versions available"
                  }
                  label="Age"
                  renderValue={(selected) =>
                    selected ? selected : "Select version"
                  }
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Select Version
                  </MenuItem>
                  {versions &&
                    versions.map((version) => {
                      return (
                        <MenuItem
                          key={version}
                          value={version}
                          onClick={() => {
                            fetchDesignObject(selectedPage, version, 'sidebarNav version select');
                            setVersion(version)
                            removeAllIcons();
                            setOpenedSidebar("");
                            setActiveOption("");
                          }}
                        >
                          {version}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>

              <Button
                variant={
                  activeOption === "versionsTab" ? "contained" : "outlined"
                }
                color={activeOption === "versionsTab" ? "primary" : "secondary"}
                onClick={(e) => {
                  setOpenedSidebar("versionsTab");
                  setActiveOption("versionsTab");
                }}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Versions Management
              </Button>
            </Grid>
          </Box>
        )}
      </Grid>
    );
  } else {
    return (
      <SettingsIcon
        style={{ cursor: "pointer", zIndex: "1" }}
        onClick={() => setIsSidebarOpen(true)}
      />
    );
  }
};

export default SidebarNav;
